import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { useAuth } from '../../state-management';
import { Message, Loader, TextField } from '../../components';
import './styles.scss';

const ChangePasswordForm: React.FC = () => {
  const { state, changePassword } = useAuth();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [msg, setMsg] = React.useState('');

  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSubmit = () => {
    setLoading(true);
    changePassword(newPassword, confirmPassword).then((res: any) => {
      if (res.status === 200) {
        setLoading(false);
        setMsg('New password has been saved');
      }
    });
  };

  return (
    <>
      <div
        onClick={handleClick}
        aria-controls='change-password-dialog'
        aria-haspopup='true'
      >
        Change Password
      </div>
      <Dialog
        className='lg-change-password'
        open={open}
        onClose={handleClick}
        aria-labelledby='change-password'
        fullWidth
      >
        <DialogTitle
          id='change-password'
          className='lg-change-password--title'
          disableTypography
        >
          Change Password
        </DialogTitle>

        <DialogContent className='lg-change-password--content'>
          <Message
            severity={state.error ? 'error' : 'success'}
            message={state.error || msg}
          />
          <TextField
            value={newPassword}
            id='new-password'
            label='New Password'
            type='password'
            onChange={(e) => setNewPassword(e.target.value)}
            handleSubmit={handleSubmit}
          />

          <TextField
            value={confirmPassword}
            id='confirm-password'
            label='Confirm Password'
            type='password'
            onChange={(e) => setConfirmPassword(e.target.value)}
            handleSubmit={handleSubmit}
          />
        </DialogContent>

        <DialogActions className='lg-change-password--actions'>
          <Loader loading={loading} />
          <Button
            onClick={handleSubmit}
            color='primary'
            className='lg-change-password--btn'
            classes={{
              label: 'lg-change-password--btn-label',
            }}
          >
            Submit
          </Button>
          <Button
            onClick={handleClick}
            color='primary'
            className='lg-change-password--btn'
            classes={{
              label: 'lg-change-password--btn-label',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangePasswordForm;
